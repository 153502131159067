import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import SvgColor from 'src/components/svg-color';
import useStores from 'src/hooks/use-stores';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  earth: icon('ic_earth'),
  coins: icon('ic_coin'),
  store: icon('ic_shop'),
  storeCategory: icon('shop-sign-bag'),
  phone: icon('ic_phone_off'),
  home: icon('ic_home'),
  bot: icon('ic_bot'),
  qr: icon('ic_qr'),
  whatsapp: icon('ic_whatsapp'),
  phonePaused: icon('ic_phone_paused')
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const [stores] = useStores();
  const { user } = useAuthContext();
  const userRoles = user?.userRoles;
  let currentStore: any;
  let showStoreDetail = false;
  if (stores && stores.length === 1) {
    currentStore = stores[0];
    if (userRoles && userRoles.length === 1) {
      showStoreDetail = userRoles[0] === 'ROLE_ADMIN_TIENDA';
    }
  }
  const data = useMemo(
    () => {
      const isAdmin = userRoles?.includes('ROLE_ADMIN');
      const COMMON_SECTIONS = 
      // [        
      //    // MANAGMENET
      //   // ----------------------------------------------------------------------
      //   {
      //     subheader: t('management'),
      //     items: [
      //       // Communication channels
      //       // ORDER
      //       {
      //         title: t('Pedidos'),
      //         path: isAdmin ? paths.dashboard.order.all : paths.dashboard.order.root,
      //         icon: ICONS.order,
      //       },
      //       {
      //         title: t('Métricas'),
      //         path: paths.dashboard.general.ecommerce,
      //         icon: ICONS.analytics,
      //       },
      //       {
      //         title: t('Conversaciones'),
      //         path: paths.dashboard.conversation,
      //         icon: ICONS.chat,
      //       },
      //     ],
      //   },
      //    // SETTINGS
      //   // ----------------------------------------------------------------------
        {
          subheader: t('setting'),
          items: [
            // Communication channels
            // ORDER
            // STORES
            {
              title: showStoreDetail ? t('Merchant'):t('Merchants'),
              path: showStoreDetail ? paths.dashboard.store.details(currentStore?.id) : paths.dashboard.store.root,
              icon: ICONS.store,              
            },
            // {
            //   title: t('productos'),
            //   path: paths.dashboard.product.root,
            //   icon: ICONS.ecommerce,             
            // },  
            /**        
            {
              title: t('Números Bloqueados'),
              path: paths.dashboard.bannedUsers.root,
              icon: ICONS.phone,
            },  
            */         
            // {
            //   title: t('Números Pausados'),
            //   path: paths.dashboard.pausedUsers.root,
            //   icon: ICONS.phonePaused,
            // },              
          ],
        }
       
      const ADMIN_SECTIONS = [
          // ADMIN
        // ----------------------------------------------------------------------
        {
          subheader: t('admin'),
          items: [
            // Communication channels    
            // USER
            // {
            //   title: t('usuarios'),
            //   path: paths.dashboard.user.list,
            //   icon: ICONS.user,
            //   roles: ['ROLE_ADMIN'],
            // },
  
            // LOCATIONS
            // {
            //   title: t('Geografia '),
            //   path: paths.dashboard.country.root,
            //   icon: ICONS.earth,
            //   children: [
            //     { title: t('paises'), path: paths.dashboard.country.root },
            //     { title: t('provincias'), path: paths.dashboard.province.root },
            //     { title: t('ciudades'), path: paths.dashboard.city.root },
            //   ],
            //   roles: ['ROLE_ADMIN'],
            // },
            // {
            //   title: t('moneda'),
            //   path: paths.dashboard.currency.root,
            //   icon: ICONS.coins,
            //   roles: ['ROLE_ADMIN'],
            // },
            // {
            //   title: t('Categorías de Comercios'),
            //   path: paths.dashboard.storeCategory.root,
            //   icon: ICONS.storeCategory,
            //   roles: ['ROLE_ADMIN'],
            // },
            // {
            //   title: t('Probar bot'),
            //   path: paths.dashboard.playground,
            //   roles: ['ROLE_ADMIN'],
            //   icon: ICONS.bot,
            // },  
            {
              title: t('Instancias'),
              path: paths.dashboard.instance.root,
              roles: ['ROLE_ADMIN'],
              icon: ICONS.qr,
            },
            // {
            //   title: t('Whatsapp Test'),
            //   path: paths.dashboard.whatsappTest.root,
            //   icon: ICONS.whatsapp,
            // },    

          ],
        },
      ]
      return isAdmin ? [...ADMIN_SECTIONS, COMMON_SECTIONS] : [];
    },
    [t, currentStore, showStoreDetail, userRoles]
  );

  return data;
}
