import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, ButtonBase, CircularProgress, Stack, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// _mock
import { _orders, ORDER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'react-redux/es/hooks/useSelector';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import { useLocales } from 'src/locales';

//
import useStores from 'src/hooks/use-stores';
import useChannelStates from 'src/hooks/use-channel-states';
import { useSnackbar } from 'src/components/snackbar';
import CategoryTableRow from '../channel-table-row';
import ChannelTableToolbar from '../channel-table-toolbar';
import ChannelTableFiltersResult from '../channel-table-filters-result';
import QRInstance from '../qr-instance';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'store', label: 'Merchant', width: 280 },
  { id: 'country', label: 'País'},
  { id: 'username', label: 'Nombre de usuario', width: 280 },
  { id: 'name', label: 'Canal', width: 180 },
  { id: 'state', label: 'Modo', width: 180 },
  { id: 'status', label: 'Conexión WhatsApp', width: 180 },
  { id: '', width: 88 },
];


const defaultFilters: {
  store: string;
  status: string;
  channelName: string;
  billingPlan: string;
  country: string;
  operationMode: string;
  name: string;
} = {
  store: '',
  status: '',
  channelName: '',
  billingPlan: '',
  country: '',
  operationMode: '',
  name: ''
};
// ----------------------------------------------------------------------

export default function InstanceListView() {
  const table = useTable({ defaultOrderBy: 'id' });

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();
  const instanceState = useSelector((state: any) => state.instanceState);

  const router = useRouter();

  const confirm = useBoolean();
  const { t } = useLocales();
  const showLoader= useBoolean();

  const [tableData, setTableData] = useState<any[]>([]);
  const [stores] = useStores({light: true});
  const [filters, setFilters] = useState(defaultFilters);
  const [isLoading, setIsLoading] = useState(false);

  const [countries, setCountries] = useState<any[]>([]);
  
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset =
    !!filters.store;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  

  const handleFilters = useCallback(

    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const [states] = useChannelStates();
  console.log(states)
  const STATUS_OPTIONS = [
    { value: 'all', label: 'Todos' },
    ...states.map((state: any) => ({
      label: state.name,
      value: state.name,
      id: state.stateId,
    })),
  ];
  const handleDeleteRow = useCallback(
    (basePath: string, id: string) => {
      axios.delete(API_ENDPOINTS.instance.delete(basePath, id)).then((val) => {
        enqueueSnackbar('Instancia eliminada con éxito');
        const deleteRow = tableData.filter((row) => row.id !== id);
        setTableData(deleteRow);
        table.onUpdatePageDeleteRow(dataInPage.length);
      }).catch((err)=>{
        
      })
    },
    [dataInPage.length, table, tableData, enqueueSnackbar]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => table.selected.includes(row.id));
    
    deleteRows.forEach((row) =>{
      handleDeleteRow(row.basePath, row.id);
    })
    /*
    setTableData(deleteRows);
    ssss
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    }); */
  }, [table, tableData, handleDeleteRow]);

  const handleViewRow = useCallback(
    (id: string) => {
       router.push(paths.dashboard.order.details(id));
    },
    [router]
  );
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  
  const notification = useSelector((state: any) => state.notification);

  useEffect(() => {
    if (notification.instanceState){
      const {event_name} = notification.instanceState;
      getChannelList(filters?.name, filters?.channelName, filters?.billingPlan, filters?.country, filters?.operationMode, filters?.status);
      }
  }, [notification, filters])
  
  useEffect(() => {    
    getChannelList(filters?.name, filters?.channelName, filters?.billingPlan, filters?.country, filters?.operationMode, filters?.status);
  }, [filters?.name, filters?.channelName, filters?.billingPlan, filters?.country, filters?.operationMode, filters?.status])
  
    const getChannelList = (store?: string, channelName?: string, billingPlan?:string, country?:string, operationMode?:string, status?:string) => {
      setIsLoading(true);
      const queryParams = [
        channelName ? `type=${channelName}` : '',
        billingPlan ? `plan_name=${billingPlan}` : '',
        country ? `country=${country}` : '',
        filters?.operationMode === 'Automático' 
          ? 'operation_mode=autopilot' 
          : filters?.operationMode === 'Manual' 
          ? 'operation_mode=off' 
          : '',
        store ? `store=${store}` : '',
        status === 'Conectado' ? 'status_active=active' : status === 'Desconectado' ? 'status_active=inactive' : ''
        // status ? `status=${status === 'Conectado' ? true : status === 'Desconectado' ? false : ''}` : ''
      ].filter(Boolean).join('&');

      const URL =  API_ENDPOINTS.channel.list(queryParams ? `?${queryParams}` : '');
      axios
      .get(URL)
      .then(({data}) => {
        console.log(data)
        setTableData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        
      })
    }
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      
        <Stack
      direction="column"
      alignItems="start"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
      Listado de instancias
      </Typography>
    </Stack>
    
        <Card>
    
        <ChannelTableToolbar
            filters={filters}
            onFilters={handleFilters}
            storeOptions={stores}
            
          /> 
          {canReset && (
            <ChannelTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              storeOptions={stores}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pr: 5, pl: 2.5 }}
          > 
            {isLoading && <CircularProgress size={48} color="info" />}
          </Stack>
          {!isLoading && (
              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
           

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                  />
  
                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <CategoryTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.basePath, row.id)}
                          onViewRow={() => handleViewRow(row.id)}
                        />
                      ))}
  
                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />
  
                    {dataFiltered.length === 0 && <TableNoData notFound={notFound} />}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          )}
        

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar selección"
        content={
          <>
            Estás seguro que deseas eliminar <strong> {table.selected.length} </strong> instancias?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Eliminar
          </Button>
        }
      />
      <QRInstance showQrModal/>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { store, status } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  // if (status !== 'all') {
  //   inputData = inputData.filter((instance) => instance.state?.name === status);
  // }

 

  
  return inputData;
}
